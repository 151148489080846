export const UserInviteType = {
    TeamUser: 0,
    TeamAdmin: 1,
}

export const UserType = {
    ...UserInviteType,
    SuperUser: 2,
    SuperAdmin: 3,
}

export const UserStatus = {
    Invited: -1,
    Active: 2,
    Inactive: 3,
    Deleted: 4
}

export const USER_INVITE_TYPE_MAPPING = {
    [UserType.TeamUser]: 'User',
	[UserType.TeamAdmin]: 'Admin',
};

export const USER_TYPE_MAPPING = {
    ...USER_INVITE_TYPE_MAPPING,
	[UserType.SuperUser]: 'Super User',
	[UserType.SuperAdmin]: 'Super Admin',
};

export const USER_STATUS_MAPPING = {
    [UserStatus.Invited]: 'Invited',
	[UserStatus.Active]: 'Active',
	[UserStatus.Inactive]: 'Inactive',
	[UserStatus.Deleted]: 'Deleted',
};