<template>
  <div class="home">
    <Navigation />
    <div style="max-width: 950px" class="container my-10">
      <div>
        <h1>Invite Member</h1>
        <div>
          <v-row style="border: 1px solid #eaeaea">
            <v-col cols="8">
              <v-text-field
                type="email"
                v-model="user.email"
                label="Email"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="user.type"
                :items="userInviteTypeSelects"
                hide-details="auto"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-btn :disabled="!user.email" @click="invite()" x-large
                >Invite<v-icon dark right>mdi-send</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row
            style="border: 1px solid #eaeaea"
            v-for="(member, i) in members"
            :key="i"
          >
            <v-col cols="4" style="align-items: center; display: flex;">
              <h6>{{ member.email }}</h6>
            </v-col>
            <v-col cols="4" style="display: flex;">
              <v-text-field
                v-if="member.id !== editIndex"
                v-model="userTypeSelects[member.type].text"
                hide-details="auto"
                readonly
                outlined
              ></v-text-field>
              <v-select
                v-else
                @input="(type) => updateType(member, type)"
                placeholder="Select User Type"
                v-model="selectedUserType"
                :items="userTypeSelects"
                hide-details="auto"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="4"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
                grid-gap: 10px;"
            >
              <!-- <v-btn
                @click="invite()"
                style="background-color: #38d400; float: right"
                dark
                x-small
              > -->
              <!-- <v-icon light style="color: red; float: right">
                mdi-minus-circle
              </v-icon> -->
              <!-- </v-btn> -->
              <v-chip style="float: right" v-if="userStatusMapper[member.status]">{{
                userStatusMapper[member.status]
              }}</v-chip>
              <v-btn
                icon
                color="dodgerblue"
                class="ma-2"
                @click="openEditor(member.id)"
              >
                <v-icon>
                  {{ editIndex === member.id ? "mdi-close" : "mdi-pencil" }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                style="float: right; color: #cc3636"
                class="ma-2"
                @click="deleteById(member.id)"
              >
                <v-icon> mdi-minus-circle </v-icon>
              </v-btn>
            </v-col>
            <!-- <hr style="width: 100%; opacity: .5" /> -->
          </v-row>
          <!-- <div v-for="(member, i) in members" :key="i">
                <div style="display: flex; justify-content: space-between">
                  <h6>{{member.email}}</h6>
                  <v-btn @click="invite()" style="background-color: #38d400" dark>remove</v-btn>
                  <hr />
                </div>
              </div> -->
        </div>
      </div>
      <div class="mb-2"></div>
    </div>
  </div>
</template>

<script>
//import ProfileService from "@/services/profileService.js";
import {
  USER_TYPE_MAPPING,
  USER_STATUS_MAPPING,
  USER_INVITE_TYPE_MAPPING,
  UserType,
  UserStatus,
  UserInviteType,
} from "@/interfaces/IUser.js";
import { userService } from "@/services";
import Navigation from "@/components/Navigation.vue";
export default {
  components: {
    Navigation,
  },
  data: () => ({
    selectedItem: "Sample",
    user: {
      email: "",
      type: +UserType.TeamUser,
    },
    editIndex: null,
    members: [],
    selectedUserType: "",
  }),
  computed: {
    userTypeSelects() {
      return Object.entries(USER_TYPE_MAPPING).map(([value, text]) => ({
        text,
        value: +value,
      }));
    },
    userInviteTypeSelects() {
      return Object.entries(USER_INVITE_TYPE_MAPPING).map(([value, text]) => ({
        text,
        value: +value,
      }));
    },
    userStatusMapper() {
      return USER_STATUS_MAPPING;
    },
  },
  created() {},
  mounted() {
    this.getMembers();
  },
  methods: {
    async updateType(member, type) {
      try {
        const res = await userService.patch({ ...member, type });
        this.editIndex = null;
        this.selectedUserType = "";
        this.getMembers();
      } catch (error) {
        console.log(error);
        this.editIndex = null;
        this.selectedUserType = "";
      }
    },
    openEditor(id) {
      if (id === this.editIndex) {
        this.editIndex = null;
      } else {
        this.editIndex = id;
      }
    },
    async deleteById(id) {
      try {
        const res = await userService.deleteById(id);
        this.getMembers();
      } catch (error) {
        console.log(error);
      }
    },
    async getMembers() {
      try {
        const res = await userService.getMembers();
        this.members = res && res.filter(user => user && !!user.email) || [];
      } catch (error) {
        console.log(error);
      }
    },
    async invite() {
      try {
        const res = await userService.invite(this.user);
        this.user.email = "";
        this.user.type = +UserType.TeamUser;
        this.getMembers();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.invite-form {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
}
</style>
